import { Box, Center, Heading, useTheme } from "@chakra-ui/react";
import { getLayout } from "components/Layout";
import {
  CONTENT_FULL_HEIGHT,
  NAVBAR_HEIGHT,
} from "components/Layout/constants";
import Mountains from "public/assets/mountains.min.svg";
import { useCurrentUserData } from "utils/currentUserHooks";
import { makeGetTransparency } from "utils/colorHooks";
import {
  useBrandLayoutHomeRedirect,
  usePrefetchBrandLayoutHome,
} from "components/Layout/brandLayoutUtils";
import PageLoadingSpinner from "components/Shared/PageLoadingSpinner";

export default function Index() {
  usePrefetchBrandLayoutHome();
  const isRedirecting = useBrandLayoutHomeRedirect();

  const userData = useCurrentUserData();
  const { firstName } = userData || {};
  const theme = useTheme();
  const getTransparency = makeGetTransparency(theme);

  if (isRedirecting !== false) {
    return <PageLoadingSpinner height={CONTENT_FULL_HEIGHT} />;
  }

  return (
    <Center
      minHeight={`calc(100vh - ${NAVBAR_HEIGHT})`}
      backgroundColor="lightBlueGrey"
      background={`linear-gradient(90deg, ${getTransparency(
        "magenta.dark",
        0.11,
      )} 0%, ${getTransparency("lightBlueGrey", 1)} 50%)`}
    >
      <Box textAlign="center" mt="-10">
        <Mountains style={{ width: "15rem", margin: "0 auto" }} />

        <Heading color="magenta.veryDark" mt={5} size="lg">
          Welcome{firstName ? `, ${firstName}` : ""}!
        </Heading>
      </Box>
    </Center>
  );
}

Index.getLayout = getLayout;
